import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function OfficeAdd (props) {

    const [office, setOffice] = useState({
        name: "",
        company_id: ""
    });

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOffice({name:"", company_id:""});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setOffice({...office, [event.target.name]: event.target.value});
    }

    const handleSave = () => {
        updateOffice(office);
        handleClose();
    }

    const updateOffice= (office) => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/create-office',
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(office)
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchOffices();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <Button onClick={handleClickOpen}>
                Add Office
            </Button>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Add Office
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>
                    <TextField
                        name="company_id"
                        value={ office.company_id }
                        label="Company ID"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                    />
                    <TextField
                        name="name"
                        value={ office.name }
                        label="Office Name"
                        inputProps={{ maxLength: 200 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                    />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default OfficeAdd;