import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import ConfirmDialog from './ConfirmDialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function UserEdit (props) {

    const [user, setUser] = useState({});

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        fetchUserDetails();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setUser({...user, [event.target.name]: event.target.value});
    }

    const handleSave = () => {
        setConfirmAction("save");
        setConfirmOpen(true);
    }

    const handleDeleteTokens = () => {
        setConfirmAction("delete tokens");
        setConfirmOpen(true);
    }

    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleConfirm = () => {
        if (confirmAction === "save")
        {
            updateUser();
            handleClose();
        }
        if (confirmAction === "delete tokens")
        {
            deleteUserTokens();
        }
        setConfirmOpen(false);
    }

    const [confirmAction, setConfirmAction] = useState(null);

    const deleteUserTokens = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/delete-user-tokens/' + props.userId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchUsers();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    const updateUser = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/update-user/' + props.userId,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchUsers();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    const fetchUserDetails = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/detail-user/' + props.userId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setUser(data.data);
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
            <EditIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {user.name}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>
                    {
                        Object.entries(user).map(entry => {
                            let key = entry[0];
                            let value = entry[1];
                            if (key !== "id" && !Array.isArray(value))
                            {
                                return <TextField
                                    key={key}
                                    name={key}
                                    value={ value === null ? "" : value }
                                    id={key}
                                    label={key}
                                    multiline
                                    inputProps={{ maxLength: 200 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />
                            }
                        })
                    }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteTokens} >Delete Tokens</Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>

            <ConfirmDialog
                title={"Confirm " + confirmAction }
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleConfirm}
            >
                Are you sure?
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default UserEdit;