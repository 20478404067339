import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import BasicTimePicker from './BasicTimePicker';
import { dateObjectToTimeString, formatDateObjectToString } from '../helpers/Helpers';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import OfficeAddUser from './OfficeAddUser';
import ConfirmDialog from './ConfirmDialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function OfficeEdit (props) {

    const [office, setOffice] = useState({
        created_at: "",
        updated_at: "",
        name: "",
        description: "",
        notes: "",
        default_average_serve_minutes: "",
        default_start_time: "",
        default_end_time: "",
        timezone: "",
        code: "",
        lock_code: ""
    });

    const [users, setUsers] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        fetchOfficeDetails();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setOffice({...office, [event.target.name]: event.target.value});
    }

    const handleSave = () => {
        setConfirmAction("save");
        setConfirmOpen(true);
    }

    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleConfirm = () => {
        if (confirmAction === "delete")
        {
            deleteUser();
        }
        if (confirmAction === "save")
        {
            updateOffice();
            handleClose();
        }
        setConfirmOpen(false);
    }

    const [confirmAction, setConfirmAction] = useState(null);

    const [deleteId, setDeleteId] = useState(null);

    const handleUserDelete = (userId) => {
        setDeleteId(userId);
        setConfirmAction("delete");
        setConfirmOpen(true);
    }

    const deleteUser = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/delete-office-user',
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                user_id: deleteId,
                office_id: props.officeId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                fetchOfficeDetails();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    const updateOffice= () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/update-office/' + props.officeId,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(office)
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchOffices();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    const fetchOfficeDetails = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/detail-office/' + props.officeId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setOffice(data.data);
            setUsers(data.data.users);
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
            <EditIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {office.name}
                        </Typography>
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {users.map(user => (
                            <Chip
                                key={user.id}
                                label={user.email + " (" + user.name + ")"}
                                onDelete={() => handleUserDelete(user.id)}
                            />
                        ))} 
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>
                    {
                        Object.entries(office).map(entry => {
                            let key = entry[0];
                            let value = entry[1];
                            if (key !== "id" && !Array.isArray(value))
                            {
                                return <TextField
                                    key={key}
                                    name={key}
                                    value={ value === null ? "" : value }
                                    id={key}
                                    label={key}
                                    multiline
                                    inputProps={{ maxLength: 200 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />
                            }
                        })
                    }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <OfficeAddUser snackbar={props.snackbar} fetchOfficeDetails={fetchOfficeDetails} officeId={props.officeId} />
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>

            <ConfirmDialog
                title={"Confirm " + confirmAction }
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleConfirm}
            >
                Are you sure?
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default OfficeEdit;