import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import { timestampToLocalDateString } from '../helpers/Helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

var array = [];

function OfficeScheduleLogs (props) {

    const [open, setOpen] = useState(false);

    const [fetching, setFetching] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);

    const [lastPage, setLastPage] = useState(0);

    const handleNextPage = () => {
        fetchData(currentPage + 1);
    }

    const [message, setMessaage] = useState("fetching data...");

    const [data, setData] = useState([]);

    const handleClickOpen = () => {
        fetchData(1);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefresh = () => {
        fetchData(1);
    }

    const fetchData = (page = 1) => {
        setFetching(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/list-schedule-logs/' + props.officeId + "?page=" + page, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setFetching(false);
            if (page === 1)
            {
                array = [];
            }
            array = array.concat(data.data.data);
            setData(array);
            setCurrentPage(parseInt(data.data.current_page));
            setLastPage(data.data.last_page);
            if (data.data.data.length < 1) setMessaage("No data");
        })
        .catch((err) => {
            setFetching(false);
            console.error(err);
        });
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
            <MenuBookIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Logs
                        </Typography>
                        <Typography variant="body1">
                        {props.officeName}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                ID: {row.id}<br />
                                {timestampToLocalDateString(row.created_at)}<br />
                                {/* {row.created_at}<br /> */}
                                {row.office_name}<br />
                                {row.schedule_date}<br />
                                {row.username}<br />
                                {row.action}
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    {data.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                    { currentPage !== lastPage ?
                        <Button fullWidth onClick={!fetching ? handleNextPage : null}>
                            {fetching ?
                            <CircularProgress size="2rem" />
                            :
                            <KeyboardArrowDownIcon fontSize='large' />
                            }
                        </Button>
                    : null }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefresh} >
                    {fetching ? 
                    <CircularProgress size="1rem" /> 
                    :
                    "Refresh"
                    }
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default OfficeScheduleLogs;