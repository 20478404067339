import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Settings from './Settings';
import Stack from '@mui/material/Stack';
import logo192 from '../logo192.png';
import List from '@mui/material/List';
import Offices from './Offices';
import Users from './Users';
import { SERVER_URL } from '../constants';
import Companies from './Companies';

function Main(props) {

    useEffect(() => {
        checkAdminToken();
    }, []);

    const checkAdminToken = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/check-token/', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message !== "Admin account check OK.")
            {
                props.logout();
            }
        })
        .catch((err) => {
            console.error(err);
        });
    }

    return(
        <Box>
            <AppBar variant='elevation={0}' style={{ background: '#ffbb00' }} position="sticky">
                <Toolbar>
                    <Stack
                    width={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                >          
                        <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        >
                            <img src={logo192} alt="" width={40} height={40} />
                            <Typography variant="h6" component="div">
                                onsulta
                            </Typography>
                        </Stack>         
                        <Settings setFontSize={props.setFontSize} notificationsFailed={props.notificationsFailed} notificationsEnabled={props.notificationsEnabled} requestNotifications={props.requestNotifications} userType={props.userType} logout={props.logout} userName={props.userName} fullName={props.fullName}/>
                </Stack>
                </Toolbar>
            </AppBar>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Companies logout={props.logout} snackbar={props.snackbar} />
                <Offices logout={props.logout} snackbar={props.snackbar} />
                <Users logout={props.logout} snackbar={props.snackbar} />
            </List>
        </Box>
    );
}

export default Main;