import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';

function SearchBox(props) {

    const [keywords, setKeywords] = useState("");
 
    const clearInput = () => {
        setKeywords("");
        props.resetParent();
    };

    const handleChange = (event) => {
        setKeywords(event.target.value);
    }

    const handleSearch = () => {
        props.searchFunction(1, keywords);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
        }
    }
 
    return (
        <React.Fragment>
            <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={keywords}
                    onChange={handleChange}
                    autoFocus
                    onKeyDown={handleKeyPress}
                />
                <IconButton disabled={!keywords ? true : false} onClick={clearInput} type="button" sx={{ p: '10px' }} aria-label="search">
                    <CancelIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton disabled={!keywords ? true : false} onClick={handleSearch} type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                
            </Paper>
        </React.Fragment>
      );
}

export default SearchBox;