const subDomain = window.location.host.split(".")[0];
export const SERVER_URL= process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : subDomain === 'devapp' ? 'https://devapi.qonsulta.net/' : 'https://api.qonsulta.net/';
export const TESTER_PASSWORD = "baroff";
export const APP_NAME='Qonsulta';
export const ALLOW_GUEST_LOGIN = true;
export const REFRESH_SECONDS = 60;
export const USER_TYPE_CUSTOMER = 300;
export const USER_TYPE_GUEST = 301;
export const USER_TYPE_MODERATOR = 200;
export const GOOGLE_CLIENT_ID = "194575111919-i9f3b8uqgv9ql2p509c9gfnllvrjehlg.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "1409165926695569";
export const VITE_VAPID_KEY = "BGvbzcvc4laN9-Sn1F1m9hy7lW9haBc2jmib7Pqs_GudJUCB73nGsdnwE_zSKt_AEfenImC3j-iCAB4by7H3Fs4";

export const BUILD_PLATFORM = "android";
export const PLATFORM_PWA = "pwa";
export const PLATFORM_ANDROID = "android";
export const PLATFORM_IOS = "ios";

const packageJson = require('../package.json'); // Adjust the path if needed
export const APP_VERSION = packageJson.version;