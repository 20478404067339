import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import logo192 from '../logo192.png';
import ResetPassword from './ResetPassword';

function Login(props) {

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [disableButton, setButtonDisabled] = useState(false);

    const handleChange = (event) => {
        setUser({...user, [event.target.name] : event.target.value});
        // setButtonDisabled(user.email && user.password ? false : true);
    }

    const handleLoginClick = () => {
        setButtonDisabled(true);
        var success = props.login(user);
        if (!success) setButtonDisabled(false);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleLoginClick();
        }
    }

    return(
        <React.Fragment>
            <Dialog open={true}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                >
                    <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                    >
                        <img src={logo192} alt="" width={50} height={50} />
                        onsulta
                        
                    </Stack>
                </Stack>
                
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography variant="body2  ">
                            LOGIN
                        </Typography>
                        <TextField
                            name="email"
                            label="E-mail"
                            onChange={handleChange} />
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            onKeyDown={handleKeyPress}
                            onChange={handleChange}/>
                            
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={disableButton}
                            onClick={handleLoginClick}
                            >
                            Login
                        </Button>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ResetPassword login={props.login} />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Login;