import React, {useState, useEffect} from 'react';
import { Divider, Stack } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from '@mui/material/Avatar';
import { ListItemButton } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SERVER_URL } from '../constants';
import OfficeEdit from './OfficeEdit';
import OfficeAdd from './OfficeAdd';
import OfficeScheduleLogs from './OfficeScheduleLogs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBox from './SearchBox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

var array = [];

function Offices(props) {
    
    const [open, setOpen] = React.useState(false);

    const [message, setMessaage] = useState("fetching data...");

    const handleClickOpen = () => {
        setOpen(true);
        fetchOffices();
    };

    const handleClose = () => {
        setOpen(false);
    };   

    const [offices, setOffices] = useState([]);

    const [searchKeywords, setSearchKeywords] = useState("");

    const [currentResults, setCurrentResults] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);

    const [lastPage, setLastPage] = useState(0);

    const handleNextPage = () => {
        if (currentResults === "list")
        {
            fetchOffices(currentPage + 1);
        }
        if (currentResults === "search")
        {
            searchOffices(currentPage + 1, searchKeywords);
        }
    }

    const [fetching, setFetching] = useState(false);

    const fetchOffices = (page = 1) => {
        setFetching(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/list-office?page=' + page, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Unauthorized access")
            {
                props.logout();
            }
            setFetching(false);
            //console.log("data.data", data.data);
            if (page === 1)
            {
                array = [];
            }
            array = array.concat(data.data.data);
            setOffices(array);
            setCurrentPage(parseInt(data.data.current_page));
            setLastPage(data.data.last_page);
            setCurrentResults("list");
            if (data.data.data.length < 1) setMessaage("No offices");
        })
        .catch((err) => {
            setFetching(false);
            console.error(err);
        });
    }

    const searchOffices = (page = 1, paramKeywords) => {
        setFetching(true);
        setSearchKeywords(paramKeywords);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/search-offices?page=' + page, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                keywords: paramKeywords
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Unauthorized access")
            {
                props.logout();
            }
            setFetching(false);
            //console.log("data.data", data.data);
            if (page === 1)
            {
                array = [];
            }
            array = array.concat(data.data.data);
            // console.log(array);
            setOffices(array);
            setCurrentPage(parseInt(data.data.current_page));
            // console.log("currentPage", currentPage);
            setLastPage(data.data.last_page);
            setCurrentResults("search");
            if (data.data.data.length < 1) setMessaage("No users");
        })
        .catch((err) => {
            setFetching(false);
            console.error(err);
        });
    }

    return(
        <React.Fragment>
            <ListItemButton onClick={handleClickOpen} alignItems="center">
                <ListItemAvatar>
                    <Avatar style={{ background: '#86BBD8' }}>
                        <StorefrontIcon />
                    </Avatar>  
                </ListItemAvatar>
                <ListItemText style={{whiteSpace: "pre-line", verticalAlign: "bottom"}}
                    primary="Offices"
                >
                </ListItemText>
            </ListItemButton>
            <Divider variant="middle" component="li" />

            <BootstrapDialog
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Offices
                        </Typography>
                    </Stack>
                    <SearchBox searchFunction={searchOffices} resetParent={fetchOffices} />
                </DialogTitle>
            
                <DialogContent sx={{ m: 0, p: 0, minHeight: "300px" }} dividers>
                    <List>
                        {offices.map(office => (
                            <ListItem key={office.id}>
                                <ListItemAvatar>  
                                    <Avatar style={{ background: '#86BBD8' }}>
                                        <StorefrontIcon fontSize='medium' />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={office.name} secondary={office.company_name}/>
                                <ListItemIcon>
                                    <OfficeEdit snackbar={props.snackbar} fetchOffices={fetchOffices} officeId={office.id} officeName={office.name} />
                                    <OfficeScheduleLogs officeId={office.id} officeName={office.name} />
                                </ListItemIcon>
                                    
                            </ListItem>
                        ))} 
                        {offices.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                    </List>
                    { currentPage !== lastPage ?
                        <Button fullWidth onClick={!fetching ? handleNextPage : null}>
                            {fetching ?
                            <CircularProgress size="2rem" />
                            :
                            <KeyboardArrowDownIcon fontSize='large' />
                            }
                        </Button>
                    : null }
                </DialogContent>
                <DialogActions>
                    <OfficeAdd snackbar={props.snackbar} fetchOffices={fetchOffices} />
                    <div style={{flex: '1 0 0'}} />
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default Offices;