import './App.css';
import React, {useState, useEffect} from 'react';
import Login from './components/Login';
import { SERVER_URL, APP_NAME, VITE_VAPID_KEY, USER_TYPE_GUEST, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from './constants';
import Main from './components/Main';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InternetChecker from './components/InternetChecker';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AlertTitle from '@mui/material/AlertTitle';

const theme = createTheme({
    typography: {
        fontFamily: [
            "Noto Sans"
        ].join(','),
        fontSize: localStorage.getItem("fontSize") ? parseInt(localStorage.getItem("fontSize")) : 14,
    },
});

function App() {

    useEffect(() => {
        checkLocalStorageToken();
    }, []);

    const setFontSize = (value) => {
        localStorage.setItem("fontSize", value.toString());
        window.location.reload();
    }

    const checkLocalStorageToken = () => {
        if (localStorage.getItem("token"))
        {
            sessionStorage.setItem("token", localStorage.getItem("token"));
            setUserName(localStorage.getItem("email"));
            setFullName(localStorage.getItem("name"));
            setUserType(localStorage.getItem("type"));
            setAuth(true);
        }
    }

    const [isAuthenticated, setAuth] = useState(false);

    const [userName, setUserName] = useState("");

    const [fullName, setFullName] = useState("");

    const [userType, setUserType] = useState(0);

    const login = (user) => {
        for(var key in user) {
            if(user[key] === "") {
                setErrorMessage("E-mail and Password are required");
                handleSnackbarOpen();
                return false;
            }
        }
        fetch(SERVER_URL + 'api/login-admin', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                const token = data.data.access_token;
                if (token) {
                    sessionStorage.setItem("token", token);
                    localStorage.setItem("token", token);
                    localStorage.setItem("email", data.data.email);
                    localStorage.setItem("name", data.data.name);
                    localStorage.setItem("type", data.data.type.toString());
                    setUserName(data.data.email);
                    setFullName(data.data.name);
                    setUserType(data.data.type);
                    checkLocalStorageToken();
                    setAuth(true);
                    return true;
                }
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                // setErrorMessage(data.message);
                // setOpen(true);
                return false;
            }
        })
        .catch(err => console.error(err))
    }

    const logout = () => {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("type");
        localStorage.removeItem("fontSize");
        setAuth(false);
        window.location.href = "/";
    }

    const [errorTitle, setErrorTitle] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const snackbar = (title, message) => {
        setErrorTitle(title);
        setErrorMessage(message);
        handleSnackbarOpen();
    }

    if (isAuthenticated)
    {
        return (
            <ThemeProvider theme={theme}>
            <React.Fragment>
                <InternetChecker />
                <Main 
                    setFontSize = {setFontSize}
                    logout={logout} 
                    appName={APP_NAME} 
                    userName={userName} 
                    fullName={fullName} 
                    userType={userType} 
                    snackbar={snackbar}
                />
                <Snackbar autoHideDuration={4000} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} onClose={handleSnackbarClose}>
                    <Alert
                        icon={<NotificationsIcon fontSize="inherit" />} 
                        onClose={handleSnackbarClose}
                        severity="info"
                        variant="standard"
                        sx={{ width: '100%', whiteSpace: 'pre-wrap' }}
                    >
                        <AlertTitle>{errorTitle}</AlertTitle>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </React.Fragment>
            </ThemeProvider>
        );
    }
    else {
        return (
            <ThemeProvider theme={theme}>
            <React.Fragment>
                <InternetChecker />
                <Login appName={APP_NAME} login={login}/>     
                <Snackbar sx={{ height: "40%" }} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {errorMessage}
                    </Alert>
                </Snackbar>
            </React.Fragment>
            </ThemeProvider>
        );
    }
}

export default App;
