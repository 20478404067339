import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import BasicTimePicker from './BasicTimePicker';
import { dateObjectToTimeString, formatDateObjectToString } from '../helpers/Helpers';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function OfficeAddUser (props) {

    const [userId, setUserId] = useState("");

    const [moderators, setModerators] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        fetchModerators();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setUserId(event.target.value);
    }

    const handleSave = () => {
        addOfficeUser();
        handleClose();
    }

    const fetchModerators = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/list-moderators', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            //console.log("data.data", data.data);
            setModerators(data.data);
        })
        .catch(err => console.error(err));
    }

    const addOfficeUser= (office) => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/admin/add-office-user/' + props.officeId,
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                user_id: userId
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                props.fetchOfficeDetails();
                props.snackbar("Message", data.message);
            }
            else {
                props.snackbar("Error", data.message);
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <Button onClick={handleClickOpen}>
                Add User
            </Button>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Add User
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userId}
                        label="Select User"
                        onChange={handleChange}
                        >
                        {moderators.map(moderator => (
                            <MenuItem key={moderator.id} value={moderator.id}>{moderator.email + " (" + moderator.name + ")"}</MenuItem>
                        ))} 
                        </Select>
                    </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Add</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default OfficeAddUser;