import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

export function formatDate(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(dateString);
    const today = new Date();
    if(d.setHours(0,0,0,0) == today.setHours(0,0,0,0)) {
      // Date equals today's date
      return "TODAY";
    }
  
    let month = months[d.getMonth()];
    return month + " " + d.getDate() + ", " + d.getFullYear();
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDateObjectToString(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}

export function dateObjectToTimeString(date) {
  return [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    "00"
  ].join(':');
}

export function mysqlTimeToTimePickerDate(mysqlTime) {
  var date = formatDateObjectToString(new Date());
  return date + "T" + mysqlTime;
}


export function formatTime(timeString) {
    let d = new Date(); // Creates a Date Object using the clients current time

    let [hoursString, minutesString, secondsString] = timeString.split(':');
    
    d.setHours(+hoursString); // Set the hours, using implicit type coercion
    d.setMinutes(minutesString); // can pass Number or String - doesn't really matter
    d.setSeconds(secondsString);

    var hours = d.getHours();
    var minutes = d.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = "";
    strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;   
}

export function appointmentStatusToString(statusId) {
    var string = "";
    switch(statusId) {
        case 1:
          string = "Confirmed";
          break;
        case 2:
            string = "Cancelled";
          break;
        case 3:
            string = "Now Serving";
          break;
        case 4:
            string = "Done";
          break;
        case 5:
          string = "No Show";
        break;
        default:
          // code block
      }
      
    return string;   
}

export function appointmentStatusAvatarStyle(statusId) {
    var style = {};
    switch(statusId) {
        case 1:
          style = {
              backgroundColor: "#86BBD8"
            };
          break;
        case 2:
            style = {
                
            };
          break;
        case 3:
            style = {
                backgroundColor: "#86BBD8"
            };
          break;
        case 4:
            style = {
              backgroundColor: "#ffbb00"
            };
          break;
        case 5:
          style = {
            
          };
          break;
        default:
          // code block
      }
      
    return style;   
}

export function appointmentStatusToIcon(statusId) {
  switch(statusId) {
      case 1:
          return <CheckIcon />;
        break;
      case 2:
          return <ClearIcon />;
        break;
      case 3:
          return <PersonIcon />;
        break;
      case 4:
          return <EmojiEventsIcon />;
        break;
      case 5:
        return <PersonOffIcon />;
        break;
      default:
        // code block
  }
}

export function getUserTypeApiRouteRoot() {
  var root = "";
  switch(parseInt(localStorage.getItem("type"))) {
      case 300:
        root = "cust"
        break;
      case 301:
        root = "cust"
        break;
      case 200:
        root = "mod"
        break;
      default:
        // code block
    }
    
  return root;
}

export function getEstimatedTime (appointmentDate, startTime, aveMinutes, nowServing, customerNumber, numCancelled) {
    var date;
    var currentDate = new Date();
    var hours;
    var minutes;
    var ampm;
    var strTime;

    // if today
    if (new Date(appointmentDate).getMonth() + " " + new Date(appointmentDate).getDate() === currentDate.getMonth() + " " + currentDate.getDate())
    {
        if (nowServing === 0)
        {
            date = new Date(appointmentDate + " " + startTime);
            // if current time < start_time
            // est time = start_time + (aveMinutes * (customerNumber - numCancelled))
            if (currentDate.getTime() < date.getTime())
            {
                date.setMinutes(date.getMinutes() + (aveMinutes * (customerNumber - numCancelled - 1)));
            }
            // if current time > start_time
            // est time = current time + (aveMinutes * (customerNumber - numCancelled))
            else{
                currentDate.setMinutes(currentDate.getMinutes() + (aveMinutes * (customerNumber - numCancelled - 1)));
                date = currentDate;
            }

            hours = date.getHours();
            minutes = date.getMinutes();
            ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            strTime = "";
            strTime = hours + ':' + minutes + ' ' + ampm;
            // console.log("strTime", strTime);

            return strTime;
        }
        else if (nowServing === customerNumber)
        {
            return "now";
        }
        else{
            date = new Date();
            var customersLeft;
            customersLeft = (customerNumber - nowServing) - numCancelled;
            date.setMinutes(date.getMinutes() + (aveMinutes * customersLeft));
            hours = date.getHours();
            minutes = date.getMinutes();
            ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            strTime = "";
            strTime = hours + ':' + minutes + ' ' + ampm;
            if (customersLeft === 1) strTime = hours + ':' + minutes + ' ' + ampm + ' or earlier';
            return strTime;
        }
    }
    else // future date
    {
      if (nowServing === 0)
      {
        date = new Date(appointmentDate + " " + startTime);
        var customersLeft;
        customersLeft = (customerNumber - nowServing) - numCancelled;
        date.setMinutes(date.getMinutes() + (aveMinutes * (customerNumber - numCancelled - 1)));
        hours = date.getHours();
        minutes = date.getMinutes();
        ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        strTime = "";
        strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;   
      }
      else{
        date = new Date(appointmentDate + " " + startTime);
        var customersLeft;
        customersLeft = (customerNumber - nowServing) - numCancelled;
        date.setMinutes(date.getMinutes() + (aveMinutes * customersLeft));
        hours = date.getHours();
        minutes = date.getMinutes();
        ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        strTime = "";
        strTime = hours + ':' + minutes + ' ' + ampm;
        if (customersLeft === 1) strTime = hours + ':' + minutes + ' ' + ampm + ' or earlier';
        return strTime;   
      }
        
    }
    
}

export function timestampToLocalDateString(timestamp) {
  var timestampArray = timestamp.split("T");
  var utcDateTimeString = timestampArray[0] + " " + timestampArray[1].split(".")[0];
  var utc = new Date(utcDateTimeString);
  var offset = utc.getTimezoneOffset();
  var local = new Date(utc.getTime() - offset * 60000);
  var dateArray = local.toString().split(" ");
  var dateString = dateArray[0] + " " +  dateArray[1] + " " +  dateArray[2] + " " +  dateArray[3] + " " +  dateArray[4];
  return dateString;
}

export const buttonStyle = {
  display: [
    "-webkit-inline-box",
    "-webkit-inline-flex",
    "-ms-inline-flexbox",
    "inline-flex"
  ],
  WebkitAlignItems: "center",
  WebkitBoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  WebkitBoxPack: "center",
  msFlexPack: "center",
  WebkitJustifyContent: "center",
  justifyContent: "center",
  position: "relative",
  boxSizing: "border-box",
  WebkitTapHighlightColor: "transparent",
  backgroundColor: "transparent",
  outline: "0",
  border: "0",
  margin: "0",
  borderRadius: ["0", "4px"],
  padding: ["0", "6px 8px"],
  cursor: "pointer",
  WebkitUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  verticalAlign: "middle",
  MozAppearance: "none",
  WebkitAppearance: "none",
  WebkitTextDecoration: "none",
  textDecoration: "none",
  color: "#1976d2",
  fontFamily: "Noto Sans",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: 1.75,
  textTransform: "uppercase",
  minWidth: "64px",
  WebkitTransition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
}

export const downloadCsv = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
  
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
          if (line !== '') line += ',';
  
          line += array[i][index];
          }
          str += line + '\r\n';
      }
      return str;
  };

  const downloadCSV = () => {
      const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  downloadCSV();
}

export const downloadCsvAndroid = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
  
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
          if (line !== '') line += ',';
  
          line += array[i][index];
          }
          str += line + '\r\n';
      }
      return str;
  };

  const downloadCSV = () => {
      const writeSecretFile = async () => {
        await Filesystem.writeFile({
          path: `${fileName}.csv`,
          data: convertToCSV(data),
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
        });
    };
    writeSecretFile();
  };

  downloadCSV();
}
